import React from "react";
import { useParams } from "react-router-dom";
import { useFetchBeacon } from "../../api/beacons";
import Loading from "../../components/Loading";
import BeaconDetails from "./BeaconDetails";
import OpenBeacon from "./OpenBeacon";

function ViewBeacon() {
  let { slug: id } = useParams();
  const { isLoading, data: beaconData } = useFetchBeacon(id);
  if (isLoading) return <Loading />;
  return beaconData?.approved_collaborator ? (
    <BeaconDetails beaconData={beaconData} />
  ) : (
    <OpenBeacon beaconData={beaconData} />
  );
}

export default ViewBeacon;
