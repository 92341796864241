import "./Footer.scss";

import React from "react";
import { Image, Nav } from "react-bootstrap";
import logo from "../assets/images/ip_logo.png";
import facebookIcon from "../assets/images/icons/social/fb2.png";
import linkedinIcon from "../assets/images/icons/social/linkedin2.png";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className=" d-flex justify-content-between ">
        <div className="brand d-flex align-items-center gap-3 ps-5">
          <Image src={logo} width={32} />{" "}
          <strong>
            Impart Partners Network<sup>TM</sup>
          </strong>
        </div>
        <div className="text-center links">
          <Nav.Link as={NavLink} to="/support">
            Support
          </Nav.Link>
          <Nav.Link as={NavLink} to="/privacy-policy">
            Privacy Policy
          </Nav.Link>
          <Nav.Link as={NavLink} to="/terms-and-agreements">
            Terms & Agreements
          </Nav.Link>
        </div>
      </div>
      <div className="copyright text-end py-3 mt-3">
        &copy; {new Date().getFullYear()} Impart Inc. All rights reserved.
      </div>
    </div>
  );
}

export default Footer;
