import OrgHeader from "../../components/navigation/OrgHeader";
import BeaconActivityGrid from "../../components/dashboard/BeaconActivityGrid";

function BeaconsActivity() {
  return (
    <>
      <OrgHeader />
      <BeaconActivityGrid collapsible={false} />
    </>
  );
}

export default BeaconsActivity;
