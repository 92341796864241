import "./Dashboard.scss";

import React from "react";
import OrgBadge from "../components/OrgBadge";
import { Col, Row } from "react-bootstrap";
import Box from "../components/Box";

import networkIcon from "../assets/images/icons/network.png";
import collaboratorsIcon from "../assets/images/icons/handshake.png";
import beaconsPendingIcon from "../assets/images/icons/circular_arrow.png";
import beaconsCompletedIcon from "../assets/images/icons/cube.png";
import BeaconActivityGrid from "../components/dashboard/BeaconActivityGrid";
import AssetTable from "../components/dashboard/AssetTable";
import BeaconDraftsTable from "../components/dashboard/BeaconDraftsTable";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import useAuth from "../hooks/useAuth";
import { useFetchStats } from "../api/dashboard";
import CommunityRequestsTable from "../components/dashboard/CommunityRequestsTable";

function Dashboard() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { data: dashboardStats, isLoading: statsLoading } = useFetchStats();
  return (
    <div className="dashboard ">
      <OrgBadge org={auth?.user?.organization} />
      <Row>
        <Col lg={3}>
          <Box
            className="stat-box"
            loading={statsLoading}
            title="My Network"
            icon={networkIcon}
            onClick={() => navigate("/my-network")}>
            <span className="value">{dashboardStats?.result?.orgs_count}</span>
          </Box>
        </Col>
        <Col lg={3}>
          <Box
            className="stat-box"
            loading={statsLoading}
            title="collaborations"
            icon={collaboratorsIcon}
            onClick={() => navigate("/collaborations")}>
            <span className="value">
              {dashboardStats?.result?.open_collaboration_requests_count}
            </span>
          </Box>
        </Col>
        <Col lg={3}>
          <Box
            className="stat-box"
            loading={statsLoading}
            title="requests in progress"
            icon={beaconsPendingIcon}
            onClick={() => navigate("/beacons/published")}>
            <span className="value">
              {dashboardStats?.result?.beacons_published_count}
            </span>
          </Box>
        </Col>
        <Col lg={3}>
          <Box
            className="stat-box"
            loading={statsLoading}
            title="Completed requests"
            icon={beaconsCompletedIcon}
            onClick={() => navigate("/beacons/completed")}>
            <span className="value">
              {dashboardStats?.result?.beacons_published_count}
            </span>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box className="grid-box">
            <BeaconActivityGrid collapsible={true} limit={10} />
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box className="grid-box">
            <CommunityRequestsTable collapsible={true} limit={10} />
          </Box>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box className="grid-box">
            <BeaconDraftsTable collapsible={true} />
          </Box>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
