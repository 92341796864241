import React, { useMemo, useState } from "react";
import DataGrid from "../DataGrid";
import {
  useFetchBeaconDrafts,
  useFetchCommunityRequests,
} from "../../api/beacons";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback";
import OrgBadge from "../OrgBadge";
import CollaborateButton from "../beacon/CollaborateButton";

function CommunityRequestsTable({ collapsible }) {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data } = useFetchCommunityRequests(pageIndex);
  const navigate = useNavigate();
  const location = useLocation();

  const columns = useMemo(
    () => [
      {
        header: "Organization",
        accessorKey: "org",
        cell: ({ cell }) => (
          <>
            <OrgBadge org={cell.row.original.organization} mini />
          </>
        ),
      },
      {
        header: "Assets",
        accessorFn: (row) => row.asset_packages[0]?.item,
      },
      { header: "Address", accessorKey: "pickup_complete_address", width: 150 },
      {
        header: "Date",
        accessorKey: "created_at_date",
        cell: ({ cell }) => {
          return new Date(cell.row.original.created_at).toLocaleDateString();
        },
      },
      {
        header: "Time",
        accessorKey: "created_at_time",
        cell: ({ cell }) => {
          return new Date(cell.row.original.created_at).toLocaleTimeString(
            "en-US",
            { hour: "numeric", minute: "2-digit" }
          );
        },
      },
      {
        header: "",
        accessorKey: "rowid",
        cell: ({ cell }) => (
          <CollaborateButton beaconData={cell.row.original} />
        ),
      },
    ],
    []
  );

  return (
    <div>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetKeys={[location.key]}>
        <DataGrid
          title="Community Requests"
          columns={columns}
          isLoading={isLoading}
          data={data?.result?.data ?? []}
          onRowClick={(e, row) => navigate(`/beacons/${row.slug}`)}
          pageIndex={pageIndex}
          setPagination={setPagination}
          pageCount={data?.result?.last_page}
        />
      </ErrorBoundary>
    </div>
  );
}

export default CommunityRequestsTable;
