import React from "react";
import { Link } from "react-router-dom";
import { BeaconAssetStatus, CollaborationStatus } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { isCollaborator, isCreator } from "../../utils";
import Button from "../Button";

function BeaconActionButton({
  beaconData,
  setShowReleaseAssetsModal,
  setShowAcceptAssetsModal,
  setShowCompleteBeaconModal,
  setShowRejectAssetsModal,
}) {
  const { auth } = useAuth();

  const collaborator = isCollaborator(beaconData, auth?.user);
  const creator = isCreator(beaconData, auth?.user);
  const beaconCompleted =
    beaconData?.collaboration?.status === CollaborationStatus.COMPLETED;
  const packageStatus = beaconData?.collaboration?.asset_package_status;
  const assetsReady =
    packageStatus === BeaconAssetStatus.BEACON_ASSETS_ACTIVITY_INITIATED;
  const assetsReleased =
    packageStatus === BeaconAssetStatus.BEACON_ASSETS_ACTIVITY_RELEASED;
  const assetsAccepted =
    packageStatus === BeaconAssetStatus.BEACON_ASSETS_ACTIVITY_ACCEPTED;

  //   <Button variant="success" disabled>
  //   Collaboration Completed
  // </Button>
  return (
    <div className="beacon-action py-3 mt-3">
      {beaconCompleted ? (
        ""
      ) : (
        <>
          {assetsReady && (
            <Button
              variant="primary"
              className="reverse"
              disabled={collaborator}
              onClick={() => setShowReleaseAssetsModal(true)}>
              {creator ? "Release Assets" : "Waiting For Assets"}
            </Button>
          )}

          {assetsReleased && (
            <>
              <Button
                variant="success"
                disabled={creator}
                onClick={() => setShowAcceptAssetsModal(true)}>
                {creator ? "Assets Released" : "Accept Assets"}
              </Button>
              {collaborator && (
                <Link
                  to="#"
                  variant="danger"
                  className="ms-3 fs-5 text-black"
                  onClick={() => setShowRejectAssetsModal(true)}>
                  Reject Assets
                </Link>
              )}
            </>
          )}
          {assetsAccepted && (
            <Button
              variant="primary"
              disabled={collaborator}
              className="reverse"
              onClick={() => setShowCompleteBeaconModal(true)}>
              {creator ? "Complete Collaboration" : "Assets Accepted"}
            </Button>
          )}
        </>
      )}
    </div>
  );
}

export default BeaconActionButton;
