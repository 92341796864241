import "./StepsProgressBar.scss";
import React from "react";
import ReleasedInactiveIcon from "../../assets/images/icons/progressbar/released_inactive.png";
import AcceptedInactiveIcon from "../../assets/images/icons/progressbar/accepted_inactive.png";
import CompletedInactiveIcon from "../../assets/images/icons/progressbar/completed_inactive.png";
import ReleasedActiveIcon from "../../assets/images/icons/progressbar/released_active.png";
import AcceptedActiveIcon from "../../assets/images/icons/progressbar/accepted_active.png";
import CompletedActiveIcon from "../../assets/images/icons/progressbar/completed_active.png";
import { Image } from "react-bootstrap";
import { BeaconAssetStatus, BeaconStatus } from "../../constants";

function StepsProgressBar({ currentStep, beaconStatus }) {
  const steps = [
    {
      id: 1,
      label: "Released",
      icon: { inactive: ReleasedInactiveIcon, active: ReleasedActiveIcon },
    },
    {
      id: 2,
      label: "Accepted",
      icon: { inactive: AcceptedInactiveIcon, active: AcceptedActiveIcon },
    },
    {
      id: 3,
      label: "Completed",
      icon: { inactive: CompletedInactiveIcon, active: CompletedActiveIcon },
    },
  ];
  if (beaconStatus === BeaconStatus.BEACON_STATUS_CANCELLED) return <></>;
  return (
    <ul className="step-progressbar my-4">
      {steps.map((step, index) => {
        let stepState = "";
        if (currentStep === BeaconAssetStatus.BEACON_ASSETS_ACTIVITY_REJECTED) {
          if (index + 1 === BeaconAssetStatus.BEACON_ASSETS_ACTIVITY_ACCEPTED) {
            stepState = "rejected";
          } else if (
            index < BeaconAssetStatus.BEACON_ASSETS_ACTIVITY_ACCEPTED
          ) {
            stepState = "complete";
          }
        } else {
          if (index < currentStep) stepState = "complete";
          else if (currentStep === index) stepState = "active";
        }
        return (
          <li className={`step ${stepState}`} key={step.id}>
            <div
              className={`d-flex justify-content-center align-items-center gap-0 ${
                stepState === "rejected" ? "mt-1" : "mt-2"
              }`}>
              <Image
                src={
                  stepState === "active" || stepState === "complete"
                    ? step.icon.active
                    : step.icon.inactive
                }
              />{" "}
              <span>{stepState === "rejected" ? "Rejected" : step.label}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default StepsProgressBar;
