import "./UserBadge.scss";

import React from "react";
import { Image } from "react-bootstrap";
import EmailIcon from "../assets/images/icons/email.png";
import PhoneIcon from "../assets/images/icons/phone.png";
import CameraIcon from "../assets/images/icons/camera.png";
import placeholder from "../assets/images/150x150.gif";
import useAuth from "../hooks/useAuth";
function UserBadge({ user, editMode, inline }) {
  const { auth } = useAuth();
  if (!user) user = auth?.user;
  return (
    <div className="user-badge d-flex flex-wrap">
      <div className="img-container me-5 position-relative">
        {editMode && (
          <Image src={CameraIcon} className="position-absolute end-0" />
        )}
        <Image src={placeholder} height={inline ? 75 : 125} roundedCircle />
      </div>
      <div className={`info ${inline ? "d-flex gap-5" : ""}`}>
        <div>
          <div className="name fw-bold">
            {user.first_name} {user.last_name}{" "}
            {/* <span className="ps-3 fw-normal">(pronouns)</span> */}
            <div
              className={`title text-black pt-2 ${inline ? "fw-normal" : ""}`}>
              Administrator
            </div>
          </div>
        </div>
        <div className="contact pt-2">
          <div className="email pb-2">
            <Image src={EmailIcon} className="pe-3" />
            {user.email}
          </div>
          <div className="phone">
            <Image src={PhoneIcon} className="pe-3" />
            {user.phone}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserBadge;
