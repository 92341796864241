import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../components/Button";
import TextInput from "../components/forms/TextInput";
import TextArea from "../components/forms/TextArea";

import useForm from "../hooks/useForm";
import Panel from "../components/Panel";
import useNotification from "../hooks/useNotification";
import useAuth from "../hooks/useAuth";

function SupportForm({ org, updateMode }) {
  const { notify } = useNotification();
  const { auth } = useAuth();
  const connectedUser = auth?.user;

  const submitForm = (finishLater) => {
    notify(
      "success",
      "Your support request was successfully submitted. Thank you!"
    );
    notify("warning", "Please enter all necessary information to continue.");
    notify(
      "error",
      "Support request cannot be sent at this time. Please try again later."
    );
    notify("info", "For your information...");
  };

  const { formData, handleChange, handleSubmit, validated } = useForm(
    {},
    () => {
      submitForm();
    }
  );

  return (
    <>
      <h1>Support</h1>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="support">
        <Row className="justify-content-center">
          <Col md={10}>
            <Panel>
              <div>
                <Row>
                  <Col>
                    <h2 className="pt-3">
                      Report an issue with the Impart App
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="orgname"
                      label="Organization"
                      name="orgname"
                      onChange={handleChange}
                      value={connectedUser.organization?.name}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Org ID"
                      id="orgId"
                      name="orgId"
                      onChange={handleChange}
                      value={connectedUser.organization?.slug}
                      required
                      readOnly
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="email"
                      label="Email address"
                      name="email"
                      onChange={handleChange}
                      value={connectedUser.organization?.email}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="phone"
                      label="Phone Number"
                      type="tel"
                      name="phone"
                      onChange={handleChange}
                      value={connectedUser.organization?.phone}
                      required
                      readOnly
                      maxLength="10"
                      pattern="[0-9]{10}"
                      validationMessage="Phone number must contain exactly 10 digits"
                    />
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={10}>
            <Panel>
              <div>
                <Row>
                  <Col>
                    <TextArea
                      label="Tell us how can we help"
                      name="notes"
                      onChange={handleChange}
                      value={formData.notes}
                      required
                    />
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col
            className="d-flex align-content-end flex-row-reverse mb-3"
            md={10}>
            <Button type="submit" size="lg" variant="primary" className="ms-2">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SupportForm;
