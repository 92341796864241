import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BeaconStatus } from "../constants";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

/*
    const BEACON_STATUS_DRAFT = 0;
    const BEACON_STATUS_IN_REVIEW = 1;
    const BEACON_STATUS_PUBLISHED = 2;
    const BEACON_STATUS_REJECTED = 3;
    is active? a new field will be added to define if a beacon is active (has active collaboration) or not
    const BEACON_ASSETS_ACTIVITY_RELEASED = 1;
    const BEACON_ASSETS_ACTIVITY_IN_TRANSIT = 2;
    const BEACON_ASSETS_ACTIVITY_COMPLETED = 3;
*/

const usePostBeacon = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (data) => {
      return axiosPrivate.post("/v1/beacons/add", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
  });
};

const useEditBeacon = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: ({ id, data }) => {
      return axiosPrivate.post(`/v1/beacons/${id}/edit`, data);
    },
  });
};

const usePublishBeacon = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(`/v1/beacons/${id}/publish`);
    },
  });
};

const useCancelBeacon = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(`/v1/beacons/${id}/cancel`);
    },
  });
};

const useReleaseAssets = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(
        `/v1/collaborations/beacons/${id}/release-assets`
      );
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ["beacons", data?.result?.slug],
      });
    },
  });
};

const useAcceptAssets = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(
        `/v1/collaborations/beacons/${id}/accept-assets`
      );
    },
    onSuccess: ({ data }) => {
      return queryClient.invalidateQueries({
        queryKey: ["beacons", data?.result?.slug],
      });
    },
  });
};
const useRejectAssets = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(`/v1/collaborations/beacons/${id}/deny-assets`);
    },
    onSuccess: ({ data }) => {
      return queryClient.invalidateQueries({
        queryKey: ["beacons", data?.result?.slug],
      });
    },
  });
};
const useCompleteBeacon = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate.post(
        `/v1/collaborations/beacons/${id}/complete-beacon`
      );
    },
    onSuccess: ({ data }) => {
      return queryClient.invalidateQueries({
        queryKey: ["beacons", data?.result?.slug],
      });
    },
  });
};

const useFetchBeacon = (id) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["beacons", id],
    queryFn: () =>
      axiosPrivate.get(`/v1/beacons/${id}`).then((res) => res.data),
    select: (data) => {
      const result = data.result;
      return {
        ...result,
        created_at_date: new Date(result.created_at).toLocaleDateString(),
        created_at_time: new Date(result.created_at).toLocaleTimeString(
          "en-US",
          { hour: "numeric", minute: "2-digit" }
        ),
      };
    },
    useErrorBoundary: true,
    enabled: !!id,
    cacheTime: 0,
  });
};

const useFetchBeacons = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["beacons", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(`/v1/beacons?page=${pageIndex + 1}`)
        .then((res) => res.data),
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchMyBeacons = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  return useQuery({
    queryKey: ["myBeacons", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(
          `/v1/beacons?filter_by[organization_id]=${
            auth?.user?.organization?.id
          }&page=${pageIndex + 1}`
        )
        .then((res) => res.data),
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchMyActiveBeacons = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["myBeacons", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(
          `/v1/beacons?include_collaborations=${1}&page=${
            pageIndex + 1
          }&filter_by[is_active]=${1}`
        )
        .then((res) => res.data),
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchBeaconsByFilters = ({ orgId, status, isActive, pageIndex }) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["filteredBeacons", orgId, status, pageIndex],
    queryFn: () => {
      let filterQuery = [];
      if (orgId) filterQuery.push(`filter_by[organization_slug]=${orgId}`);
      if (status) filterQuery.push(`filter_by[status]=${status}`);
      if (isActive) filterQuery.push(`filter_by[is_active]=${isActive}`);

      if (filterQuery.length > 1) filterQuery = filterQuery.join("&");
      else filterQuery = filterQuery[0];
      return axiosPrivate
        .get(`/v1/beacons?${filterQuery}&page=${pageIndex + 1}`)
        .then((res) => res.data);
    },
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchBeaconDrafts = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["beaconsDrafts", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(
          `/v1/beacons?filter_by[status]=${
            BeaconStatus.BEACON_STATUS_DRAFT
          }&page=${pageIndex + 1}`
        )
        .then((res) => res.data),
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useFetchCommunityRequests = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["communityRequests", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(
          `/v1/beacons/community-requests?filter_by[is_active]=0&only_subscribed=true&page=${
            pageIndex + 1
          }`
        )
        .then((res) => res.data),
    useErrorBoundary: true,
    cacheTime: 0,
  });
};

const useFetchAssetCategories = () => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["assetCategories"],
    queryFn: () =>
      axiosPrivate
        .get(`/v1/beacons/assets/asset-categories`)
        .then((res) => res.data),
    useErrorBoundary: true,
  });
};

export {
  usePostBeacon,
  useEditBeacon,
  usePublishBeacon,
  useCancelBeacon,
  useAcceptAssets,
  useCompleteBeacon,
  useRejectAssets,
  useReleaseAssets,
  useFetchBeacon,
  useFetchBeacons,
  useFetchMyBeacons,
  useFetchMyActiveBeacons,
  useFetchBeaconDrafts,
  useFetchCommunityRequests,
  useFetchBeaconsByFilters,
  useFetchAssetCategories,
};
