import OrgHeader from "../../components/navigation/OrgHeader";
import BeaconDraftsTable from "../../components/dashboard/BeaconDraftsTable";

function BeaconDrafts() {
  return (
    <>
      <OrgHeader />
      <BeaconDraftsTable collapsible={false} />
    </>
  );
}

export default BeaconDrafts;
