import "./DataGrid.scss";

import React, { useMemo, useState } from "react";
import { Collapse, Image, Pagination } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import arrowDownIcon from "../assets/images/icons/arrow_down.png";
import arrowRightIcon from "../assets/images/icons/arrow_right.png";

// DOCS: https://tanstack.com/table/v8/docs/examples/react/pagination-controlled

function DataGrid({
  title,
  columns,
  data,
  isLoading,
  pageCount,
  collapsible,
  onRowClick,
  className,
  setPagination,
  pageIndex,
  comingSoon,
}) {
  const pagination = useMemo(
    () => ({
      pageIndex,
    }),
    [pageIndex]
  );

  const table = useReactTable({
    columns: comingSoon
      ? [{ header: "Coming Soon...", accessorKey: "comingSoon" }]
      : columns,
    data: comingSoon ? [{ comingSoon: "" }] : data,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });
  const [open, setOpen] = useState(true);
  const style = { cursor: collapsible ? "pointer" : "auto" };
  let currentPage = pageIndex + 1;
  let nextPage = currentPage + 1;
  let prevPage = currentPage - 1;
  return (
    <div
      className={`data-grid ${className} ${comingSoon ? "coming-soon" : ""}`}>
      <div className="d-flex justify-content-between flex-wrap pb-2">
        <div>
          {title && (
            <h3
              onClick={() => setOpen(collapsible ? !open : open)}
              style={style}>
              {collapsible && (
                <Image src={open ? arrowDownIcon : arrowRightIcon} />
              )}{" "}
              {title}
            </h3>
          )}
        </div>
      </div>
      <Collapse in={open}>
        <div>
          <BTable responsive>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      onClick={(e) =>
                        onRowClick && !comingSoon
                          ? onRowClick(e, row.original)
                          : false
                      }>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>{isLoading ? "Loading..." : "No Data to display"}</td>
                  <td colSpan="100%"></td>
                </tr>
              )}
            </tbody>
          </BTable>
          {!comingSoon && (
            <Pagination className="justify-content-end">
              <Pagination.First
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              />
              <Pagination.Prev
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              />
              {prevPage > 1 && <Pagination.Ellipsis />}

              {prevPage >= 1 && (
                <Pagination.Item
                  onClick={() => table.setPageIndex(prevPage - 1)}>
                  {prevPage}
                </Pagination.Item>
              )}
              <Pagination.Item active>{currentPage}</Pagination.Item>
              {nextPage <= table.getPageCount() && (
                <Pagination.Item
                  onClick={() => table.setPageIndex(nextPage - 1)}>
                  {nextPage}
                </Pagination.Item>
              )}

              {nextPage < table.getPageCount() && <Pagination.Ellipsis />}

              <Pagination.Next
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              />
              <Pagination.Last
                onClick={() => table.setPageIndex(table.getPageCount())}
                disabled={!table.getCanNextPage()}
              />
            </Pagination>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default DataGrid;
