import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { useSubscribeToOrg } from "../api/organizations";
import useAuth from "../hooks/useAuth";
import useNotification from "../hooks/useNotification";
import Button from "./Button";

function SubscribeButton({ org, className }) {
  const queryClient = useQueryClient();
  const subscribeToOrgMutation = useSubscribeToOrg();
  const { notify } = useNotification();
  const { auth } = useAuth();

  const subscribe = useCallback(
    (org) => {
      subscribeToOrgMutation.mutate(org?.slug, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["organizations"] });
          notify("success", `You are now subscribed to ${org.name}`);
        },
        onError: (error) => {
          notify("error", "Something went wrong, please try again later.");
        },
      });
    },
    [notify, queryClient, subscribeToOrgMutation]
  );
  const isMyOrg = org?.slug === auth?.user?.organization?.slug;

  return (
    <>
      {org && !isMyOrg && (
        <Button
          title="Subscribe"
          variant="primary"
          loading={subscribeToOrgMutation.isLoading}
          className={`${className} ${
            org.is_subscribed ? "checked" : "unchecked"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            if (!org.is_subscribed) subscribe(org);
          }}>
          {org.is_subscribed ? "Subscribed" : "Subscribe"}
        </Button>
      )}
    </>
  );
}

export default SubscribeButton;
