import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Checkbox from "../../components/forms/Checkbox";
import TextInput from "../../components/forms/TextInput";
import PasswordInput from "../../components/forms/PasswordInput";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";

const LOGIN_URL = "/login";

function Login() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showActivationMessage, setShowActivationMessage] = useState(false);
  const [err, setErr] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [, setPresistedAuth] = useLocalStorage("auth", "");

  useEffect(() => {
    setErr("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response?.data?.result;
      const authorization = data?.authorisation;
      const user = data?.user;
      const accessToken = authorization?.token;
      const authObj = { user, accessToken };
      setAuth(authObj);
      setPresistedAuth(authObj);
      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (error) {
      const code = error?.response?.data?.result?.code;
      setShowActivationMessage(code === "INACTIVE_USER");
      setErr(getErrorMessage(code));
    }
  };

  const getErrorMessage = (code) => {
    if (!code) {
      return "Something went wrong, please try again later!";
    }
    switch (code) {
      case "INVALID_CREDENTIALS":
        return "Wrong Email or Password";
      case "INACTIVE_USER":
        return "Your account is inactive. Please contact support to activate your account.";
      case undefined:
        return "Something went wrong, please try again later!";
      default:
        return "Login Failed";
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="fs-4 fw-bold">Login</h2>
          <Link to="/auth/signup" className="mt-3 fs-6">
            Don't have an account? Sign up Now!
          </Link>
        </div>
        {showActivationMessage && (
          <Alert variant="info">
            <strong>Complete Your Account Activation</strong>
            <br />
            <br /> Thank you for joining the Impact Partners Network. To access
            your account and start benefiting from all our features, please{" "}
            <Link
              to="https://calendly.com/shawnaruff/60-min-meeting"
              target="_blank">
              schedule your onboarding meeting
            </Link>
            . <br />
            <br /> During this quick session, we'll walk you through the
            platform, ensure your account is set up, and answer any questions
            you may have. <br />
            Your account will remain inactive until the onboarding meeting is
            completed, so please{" "}
            <Link
              to="https://calendly.com/shawnaruff/60-min-meeting"
              target="_blank">
              book your spot today
            </Link>{" "}
            and get started right away.
          </Alert>
        )}
        {err && !showActivationMessage && <Alert variant="danger">{err}</Alert>}
        <TextInput
          name="email"
          label="email"
          id="email"
          placeholder="name@intentionlabs.io"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <PasswordInput
          name="password"
          type="password"
          label="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div className="actions d-flex gap-3 mb-3">
          <Link to="../forgot-username">Forget username?</Link>
          <Link to="../forgot-password">Forget password?</Link>
        </div>
        <Button variant="primary" className="w-100 my-3" type="submit">
          Sign In
        </Button>
        <Checkbox
          className="remember-me my-3"
          label="Remember me"
          onChange={() => setRememberMe(!rememberMe)}
          checked={rememberMe}
        />
      </Form>
      <p>
        By signing in the the Impact Partners platform you agree to{" "}
        <Link to="https://impactpartnersnetwork.com/legal" target="_blank">
          terms of service
        </Link>
        ,{" "}
        <Link to="https://impactpartnersnetwork.com/legal" target="_blank">
          social and privacy policies
        </Link>
        , and you agree to be an{" "}
        <Link to="https://impactpartnersnetwork.com/legal" target="_blank">
          awesome human
        </Link>
        .
      </p>
    </>
  );
}

export default Login;
