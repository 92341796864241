import "./BeaconActivityGrid.scss";

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid from "../DataGrid";
import { useFetchMyActiveBeacons, useFetchMyBeacons } from "../../api/beacons";
import { BeaconStatus } from "../../constants";

function BeaconActivityGrid({ collapsible, limit }) {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data } = useFetchMyBeacons(pageIndex);

  let beacons = data?.result?.data;
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        header: "Request ID",
        accessorKey: "slug",
      },
      { header: "Title", accessorKey: "title" },
      {
        header: "Asset Description",
        accessorKey: "assetDescription",
        cell: ({ cell }) => {
          const assets = cell.row.original.asset_packages;
          if (assets?.length === 0) return <></>;
          const asset = assets[0];
          return (
            <>
              {asset?.category?.name}{" "}
              <strong className="d-block">{asset?.item}</strong>
            </>
          );
        },
      },
      {
        header: "Originating Location",
        accessorKey: "pickup_complete_address",
      },
      { header: "Date & Time", accessorKey: "created_at" },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ cell }) => {
          const beaconStatus = cell.row.original.status;

          const isCompleted = false,
            isAccepted = beaconStatus === BeaconStatus.BEACON_STATUS_PUBLISHED,
            isRejected = beaconStatus === BeaconStatus.BEACON_STATUS_REJECTED;
          return (
            <>
              <span
                className={`status text-capitalize ${
                  isCompleted ? "completed" : ""
                } ${isAccepted ? "accepted" : ""} ${
                  isRejected ? "rejected" : ""
                }`}>
                {isCompleted && "Completed"}
                {isAccepted && "Accepted"}
                {isRejected && "Rejected"}
              </span>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="beacon-activity-grid">
      <DataGrid
        title="My Community Requests"
        columns={columns}
        onRowClick={(e, row) => navigate(`/beacons/${row.slug}`)}
        isLoading={isLoading}
        data={beacons ?? []}
        collapsible={collapsible}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
      />
    </div>
  );
}

export default BeaconActivityGrid;
