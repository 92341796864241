import "./SideBar.scss";
import logo from "../../assets/images/ip_logo.png";
import closeIcon from "../../assets/images/icons/close.png";
import dashboardIcon from "../../assets/images/icons/dashboard.png";
import messagesIcon from "../../assets/images/icons/chat.png";
import createBeaconIcon from "../../assets/images/icons/create.png";
import createBeaconIconLight from "../../assets/images/icons/create_light.png";
import settingsIcon from "../../assets/images/icons/settings.png";
import mynetworkIcon from "../../assets/images/icons/mynetwork.png";
import commBeaconsIcon from "../../assets/images/icons/commbeacons.png";
import findOrgIcon from "../../assets/images/icons/findorg.png";
import eventsIcon from "../../assets/images/icons/calendar.png";
import bidsIcon from "../../assets/images/icons/briefcase.png";

import { useEffect, useState } from "react";
import { Image, Nav } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";

import Button from "../Button";
import ProfileBadge from "../auth/ProfileBadge";
import useAuth from "../../hooks/useAuth";
import { ChevronBarRight } from "react-bootstrap-icons";
import { OrgTypes } from "../../constants";

function SideBar() {
  const { auth } = useAuth();
  const [isOpen, setIsOpen] = useState(true);
  const { pathname } = useLocation();
  let isAuthenticated = false;

  const handleResize = () => {
    setIsOpen(window.innerWidth > 1200);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.addEventListener("resize", null);
    };
  }, []);

  const menuItems = [
    {
      label: "Community Request",
      route: "/beacons/new",
      icon: isOpen ? createBeaconIcon : createBeaconIconLight,
      featured: true,
      hidden: auth?.user?.organization?.type !== OrgTypes.NP,
    },
    {
      label: "Dashboard",
      route: "/dashboard",
      icon: dashboardIcon,
      subItems: [
        { label: "Collaborations", route: "/collaborations" },
        { label: "Community Request Activity", route: "/beacons/activity" },
        { label: "Community Request Drafts", route: "/beacons/drafts" },
      ],
    },
    {
      label: "Opportunities",
      route: "/opportunities",
      icon: bidsIcon,
    },
    {
      label: "Community Requests",
      route: "/community-requests",
      icon: commBeaconsIcon,
    },
    {
      label: "Find Organization",
      route: "/find-organization",
      icon: findOrgIcon,
    },
    {
      label: "My Network",
      route: "/my-network",
      icon: mynetworkIcon,
    },
    { label: "Messages", route: "/messages", icon: messagesIcon },
    {
      label: "Community Events",
      route: "/events/community",
      icon: eventsIcon,
      subItems: [
        {
          label: "Events followed by my organization",
          route: "/events/followed",
        },
      ],
    },
    {
      label: "Settings",
      route: "/settings/my-profile",
      icon: settingsIcon,
      subItems: [
        { label: "Event Listings", route: "/settings/events" },
        { label: "User Profile", route: "/settings/my-profile" },
        { label: "Organization Profile", route: "/settings/org-profile" },
      ],
    },
  ];

  return (
    <div className={`sidebar-wrapper g-0 ${isOpen ? "open" : "closed"}`}>
      <div id="sidebar" className={`h-100`}>
        <div className="sidebar-header d-flex">
          {isOpen ? (
            <>
              <div className={`brand d-flex align-items-center gap-3`}>
                <Image src={logo} width={33} />
                {isOpen && <strong>Impact Partners Network</strong>}
              </div>
              <Button variant="light" onClick={() => setIsOpen(!isOpen)}>
                <Image src={closeIcon} />
              </Button>
            </>
          ) : (
            <Button
              variant="light"
              onClick={() => setIsOpen(!isOpen)}
              className="ps-0">
              <ChevronBarRight size={25} />
            </Button>
          )}
        </div>
        <div className="sidebar-content">
          <ProfileBadge
            user={{ name: "Guest", picture: "https://via.placeholder.com/60" }}
            mini={!isOpen}
            isGuest={!isAuthenticated}
          />
          <Nav
            variant="pills"
            className="flex-column mb-auto"
            activeKey={pathname}>
            {menuItems.map((item, idx) => {
              if (item.hidden) return null;
              return (
                <Nav.Item
                  key={idx}
                  className={`${item.featured && isOpen ? "featured" : ""}`}>
                  <Nav.Link
                    eventKey={item.route === "/dashboard" ? "/" : item.route}
                    as={NavLink}
                    className={` ${({ isActive }) =>
                      isActive
                        ? "active"
                        : ""} d-flex top-level align-items-center ${
                      item.subItems ? "dropdown-toggle" : ""
                    }`}
                    data-toggle="dropdown"
                    title={item.label}
                    to={item.route}>
                    <Image src={item.icon} width={22} />
                    {isOpen && <span>{item.label}</span>}
                  </Nav.Link>
                  {item.subItems && (
                    <ul className="dropdown-menu">
                      {item.subItems.map((subItem, subIdx) => (
                        <Nav.Item key={`${idx}-${subIdx}`}>
                          <Nav.Link
                            as={NavLink}
                            title={subItem.label}
                            to={subItem.route}>
                            <span>{subItem.label}</span>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </ul>
                  )}
                </Nav.Item>
              );
            })}
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
