import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import OrgBadge from "../../components/OrgBadge";
import OrgHeader from "../../components/navigation/OrgHeader";
import { useFetchCommunityRequests } from "../../api/beacons";
import DataGrid from "../../components/DataGrid";
import CollaborateButton from "../../components/beacon/CollaborateButton";

function CommunityRequests() {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data } = useFetchCommunityRequests(pageIndex);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        header: "Organization",
        accessorKey: "org",
        cell: ({ cell }) => (
          <>
            <OrgBadge org={cell.row.original.organization} mini />
          </>
        ),
      },
      {
        header: "Assets",
        accessorFn: (row) => row.asset_packages[0]?.item,
      },
      { header: "Address", accessorKey: "pickup_complete_address", width: 150 },
      {
        header: "Date",
        accessorKey: "created_at_date",
        cell: ({ cell }) => {
          return new Date(cell.row.original.created_at).toLocaleDateString();
        },
      },
      {
        header: "Time",
        accessorKey: "created_at_time",
        cell: ({ cell }) => {
          return new Date(cell.row.original.created_at).toLocaleTimeString(
            "en-US",
            { hour: "numeric", minute: "2-digit" }
          );
        },
      },
      {
        header: "",
        accessorKey: "rowid",
        cell: ({ cell }) => (
          <CollaborateButton beaconData={cell.row.original} />
        ),
      },
    ],
    []
  );

  return (
    <>
      <OrgHeader />
      <div className="beacons-list">
        <DataGrid
          title="Community Requests"
          columns={columns}
          isLoading={isLoading}
          data={data?.result?.data ?? []}
          onRowClick={(e, row) => navigate(`/beacons/${row.slug}`)}
          pageIndex={pageIndex}
          setPagination={setPagination}
          pageCount={data?.result?.last_page}
        />
      </div>
    </>
  );
}

export default CommunityRequests;
