import "./ProfileBadge.scss";
import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function ProfileBadge({ user, mini, isGuest }) {
  const { auth } = useAuth();
  const guest = {
    first_name: "Guest",
    last_name: "",
    picture: "https://via.placeholder.com/60",
  };
  user = !auth ? guest : auth.user;
  return (
    <div className="user-info p-2 p-lg-4">
      <Link
        to="/settings/my-profile"
        className="text-decoration-none d-flex justify-content-center gap-2">
        <div>
          <Image src={guest?.picture} height={50} roundedCircle />
        </div>
        {!mini && (
          <div className="ms-2">
            <strong>
              {user?.first_name} {user?.last_name}
            </strong>
            <div className="title">Admin</div>
            {/* <div className="pronouns">(pronouns)</div> */}
          </div>
        )}
      </Link>
    </div>
  );
}

export default ProfileBadge;
