import "./OrgProfile.scss";

import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Button from "../../components/Button";
import coverPhoto from "../../assets/images/cover_photo_placeholder.png";
import verified from "../../assets/images/verified.png";
import orgLogo from "../../assets/images/logo_placeholder.gif";
import coverPhotoPlaceholder from "../../assets/images/cover_photo_placeholder.jpg";
import locationIcon from "../../assets/images/icons/pin.png";
import facebookIcon from "../../assets/images/icons/social/fb.png";
import twitterIcon from "../../assets/images/icons/social/twitter.png";
import instagramIcon from "../../assets/images/icons/social/instagram.png";
import linkedinIcon from "../../assets/images/icons/social/linkedin.png";
import tiktokIcon from "../../assets/images/icons/social/tiktok.png";
import BeaconsList from "../../components/beacon/BeaconsList";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useEditOrg } from "../../api/organizations";
import useNotification from "../../hooks/useNotification";
import { BeaconStatus } from "../../constants";
import { formatExternalUrl, getAssetPublicUrl } from "../../utils";
import SubscribeButton from "../../components/SubscribeButton";
import { useUploadFiles } from "../../api/files";
import DataGrid from "../../components/DataGrid";

function OrgProfile({ preview, profileData }) {
  const navigate = useNavigate();
  const [publishing, setPublishing] = useState(false);
  const queryClient = useQueryClient();
  const { notify } = useNotification();
  const uploadFiles = useUploadFiles();
  const editOrgMutation = useEditOrg();
  const logoPreviewSrc =
    profileData?.logoPreview instanceof File
      ? URL.createObjectURL(profileData?.logoPreview)
      : profileData?.logoPreview?.key
      ? getAssetPublicUrl(profileData?.logoPreview)
      : orgLogo;

  const coverPhotoPreviewSrc =
    profileData?.coverPhotoPreview instanceof File
      ? URL.createObjectURL(profileData?.coverPhotoPreview)
      : profileData?.coverPhotoPreview?.key
      ? getAssetPublicUrl(profileData?.coverPhotoPreview)
      : coverPhoto;

  const publishChanges = async (data) => {
    setPublishing(true);
    const logo = data.logoPreview;
    const coverPhoto = data.coverPhotoPreview;
    let logoId = null;
    let coverPhotoId = null;

    const uploadImage = async (image) => {
      let filesData = [],
        toUpload = [];
      if (image instanceof File) {
        toUpload.push(image);
      } else {
        filesData.push(image);
      }

      if (toUpload) {
        let newFiles = await uploadFiles(toUpload);
        filesData = filesData.concat(newFiles);
      }
      return filesData[0];
    };

    if (logo instanceof File) {
      const logoData = await uploadImage(logo);
      logoId = logoData.id;
    }

    if (coverPhoto instanceof File) {
      const coverPhotoData = await uploadImage(coverPhoto);
      coverPhotoId = coverPhotoData.id;
    }
    editOrgMutation.mutate(
      {
        id: data.slug,
        data: { ...data, logo_id: logoId, cover_photo_id: coverPhotoId },
      },
      {
        onSuccess: async (data) => {
          await queryClient.invalidateQueries({
            queryKey: ["organizations", profileData?.id],
          });
          notify("success", "Your Organization profile has been updated");

          navigate(`/organization/${profileData?.slug}`);
        },
        onError: (error) => {
          notify("error", "Something went wrong, please try again later.");
        },
        onSettled: () => {
          setPublishing(false);
        },
      }
    );
  };

  useEffect(() => {
    if (!profileData) {
      navigate("/settings/org-profile");
    }
  }, [navigate, profileData]);

  return (
    <div className="org-profile">
      <Row>
        <Col>
          <div className="d-md-flex justify-content-between heading">
            {preview && (
              <>
                <h1>
                  Network Profile Preview <small>ID: {profileData?.slug}</small>
                </h1>
                <div>
                  <Button
                    variant="primary"
                    loading={publishing}
                    disabled={publishing}
                    onClick={(e) => publishChanges(profileData)}>
                    PUBLISH
                  </Button>
                </div>
              </>
            )}
          </div>
          <div
            className="cover"
            style={{
              backgroundImage: `url(${
                preview
                  ? coverPhotoPreviewSrc
                  : profileData?.cover_photo
                  ? getAssetPublicUrl(profileData?.cover_photo)
                  : coverPhotoPlaceholder
              })`,
            }}>
            <Image
              className="rounded-circle"
              height={100}
              width={100}
              style={{ objectFit: "cover" }}
              src={
                preview ? logoPreviewSrc : getAssetPublicUrl(profileData?.logo)
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="px-md-5">
        <Col md={7}>
          <div className="org-info">
            <h2>
              {profileData?.name}{" "}
              <Image src={verified} width={20} alt="Verified" />
            </h2>
            <small className="tagline">{profileData?.tagline}</small>
          </div>
          {profileData?.description && (
            <p className="my-4">{profileData?.description}</p>
          )}
          <div className="links d-flex flex-md-row flex-column mt-md-5">
            {profileData?.website && (
              <Link
                className="btn btn-primary"
                to={{ pathname: formatExternalUrl(profileData?.website) }}
                target="_blank">
                Visit Website
              </Link>
            )}
            <div className="socials d-flex gap-3 ms-md-4 mt-3 mt-md-0">
              {profileData?.facebook_link && (
                <Link
                  to={{
                    pathname: formatExternalUrl(profileData?.facebook_link),
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={profileData?.facebook_link}>
                  <Image src={facebookIcon} />
                </Link>
              )}
              {profileData?.twitter_link && (
                <Link
                  to={{
                    pathname: formatExternalUrl(profileData?.twitter_link),
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={profileData?.twitter_link}>
                  <Image src={twitterIcon} />
                </Link>
              )}
              {profileData?.linkedin_link && (
                <Link
                  to={{
                    pathname: formatExternalUrl(profileData?.linkedin_link),
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={profileData?.linkedin_link}>
                  <Image src={linkedinIcon} />
                </Link>
              )}
              {profileData?.instagram_link && (
                <Link
                  to={{
                    pathname: formatExternalUrl(profileData?.instagram_link),
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={profileData?.instagram_link}>
                  <Image src={instagramIcon} />
                </Link>
              )}
              {profileData?.tiktok_link && (
                <Link
                  to={{ pathname: formatExternalUrl(profileData?.tiktok_link) }}
                  target="_blank"
                  rel="noreferrer"
                  href={profileData?.tiktok_link}>
                  <Image src={tiktokIcon} />
                </Link>
              )}
            </div>
          </div>
        </Col>
        <Col md={5}>
          {(profileData?.core_cause || profileData?.location) && (
            <div className="cause">
              {profileData?.core_cause && (
                <>
                  <h4>cause</h4>
                  <h3>{profileData?.core_cause}</h3>
                </>
              )}
              {profileData?.is_nonprofit && (
                <div className="verified my-2">
                  <Image src={verified} /> Verified 501(c)(3)
                </div>
              )}

              {profileData?.location && (
                <div className="location">
                  <Image src={locationIcon} /> {profileData?.location}
                </div>
              )}
              <div className="text-center">
                <SubscribeButton org={profileData} className="mt-4" />
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row className="py-5 requests">
        <Col md={12}>
          <h3>{profileData?.name} Requests</h3>
          {preview ? (
            <div className="ps-4">
              *Table will populate when profile is live.
            </div>
          ) : (
            <BeaconsList
              status={BeaconStatus.BEACON_STATUS_PUBLISHED}
              orgId={profileData?.slug}
              isActive={true}
            />
          )}
        </Col>
      </Row>
      <Row className="requests pb-5">
        <Col md={12}>
          <h3>Opportunities</h3>
          {preview ? (
            <div className="ps-4">
              *Opportunities will populate when profile is live.
            </div>
          ) : (
            <DataGrid comingSoon={true} data={[]} />
          )}
        </Col>
      </Row>
      <Row className="requests">
        <Col md={12}>
          <h3>Events</h3>
          {preview ? (
            <div className="ps-4">
              *Events will populate when profile is live.
            </div>
          ) : (
            <DataGrid comingSoon={true} data={[]} />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default OrgProfile;
