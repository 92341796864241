import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

import { Route, Routes } from "react-router";
import { Container } from "react-bootstrap";
import CreateBeacon from "./pages/beacons/CreateBeacon";
import FindOrganizations from "./pages/organizations/FindOrganizations";
import Dashboard from "./pages/Dashboard";
import Support from "./pages/Support";
import Pricing from "./pages/Pricing";
import Org from "./pages/organizations/Org";
import { ToastContainer } from "react-toastify";
import ChatPanel from "./components/ChatPanel";
import Collaborations from "./pages/beacons/Collaborations";
import CommunityEvents from "./pages/events/CommunityEvents";
import FollowedEvents from "./pages/events/FollowedEvents";
import MyNetwork from "./pages/organizations/MyNetwork";
import CommunityRequests from "./pages/beacons/CommunityRequests";
import BeaconsActivity from "./pages/beacons/BeaconsActivity";
import BeaconAssets from "./pages/beacons/BeaconAssets";
import BeaconDrafts from "./pages/beacons/BeaconDrafts";
import MainLayout from "./layouts/MainLayout";
import MyProfile from "./pages/settings/MyProfile";
import EditOrgProfile from "./pages/organizations/EditOrgProfile";
import Events from "./pages/settings/Events";
import SettingsLayout from "./layouts/SettingsLayout";
import AuthLayout from "./layouts/AuthLayout";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import RequireAuth from "./components/auth/RequireAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndAgreements from "./pages/TermsAndAgreements";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ViewBeacon from "./pages/beacons/ViewBeacon";
import EditBeacon from "./pages/beacons/EditBeacon";
import Beacons from "./pages/beacons/Beacons";
import AcceptBeaconAssetsQrView from "./pages/beacons/AcceptBeaconAssetsQrView";
import { BeaconStatus } from "./constants";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import MobileLayout from "./layouts/MobileLayout";
import Bids from "./pages/Bids";
import Messages from "./pages/Messages";
import SearchOpportunities from "./pages/opportunities/SearchOpportunities";
import Signup from "./pages/auth/Signup";
import RequireNonProfit from "./components/auth/RequireGovType";

const queryClient = new QueryClient();
const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBER_KEY,
  userId: "default",
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <PubNubProvider client={pubnub}>
        <ToastContainer className="notification" />
        <Container
          className="app d-flex flex-column min-vh-100 g-0 overflow-hidden"
          fluid>
          <Routes>
            <Route element={<MobileLayout />}>
              <Route element={<RequireAuth />}>
                <Route
                  path="/beacons/:slug/received-assets"
                  element={<AcceptBeaconAssetsQrView />}
                />
              </Route>
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
              <Route path="login" element={<Login />}></Route>
              <Route path="hubspotSignup" element={<hubspotSignup />}></Route>
              <Route path="signup" element={<Signup />}></Route>
              <Route
                path="forgot-password"
                element={<ForgotPassword />}></Route>
              <Route path="reset-password" element={<ResetPassword />}></Route>
            </Route>

            <Route element={<MainLayout />}>
              <Route element={<RequireAuth />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/opportunities/:noticeId?"
                  element={<SearchOpportunities />}
                />
                <Route path="/bids" element={<Bids />} />
                <Route path="/beacons/activity" element={<BeaconsActivity />} />
                <Route path="/beacons/assets" element={<BeaconAssets />} />
                <Route path="/beacons/drafts" element={<BeaconDrafts />} />
                <Route
                  path="/community-requests"
                  element={<CommunityRequests />}
                />
                <Route path="/collaborations" element={<Collaborations />} />
                <Route
                  path="/beacons/pending"
                  element={
                    <Beacons status={BeaconStatus.BEACON_STATUS_IN_REVIEW} />
                  }
                />
                <Route
                  path="/beacons/completed"
                  element={
                    <Beacons status={BeaconStatus.BEACON_STATUS_COMPLETED} />
                  }
                />

                <Route
                  path="/beacons/published"
                  element={
                    <Beacons status={BeaconStatus.BEACON_STATUS_PUBLISHED} />
                  }
                />
                <Route path="/beacons/:slug" element={<ViewBeacon />} />

                <Route element={<RequireNonProfit />}>
                  <Route path="/beacons/:slug/edit" element={<EditBeacon />} />
                  <Route path="/beacons/new" element={<CreateBeacon />} />
                </Route>
                <Route
                  path="/find-organization"
                  element={<FindOrganizations />}
                />
                <Route path="/my-network" element={<MyNetwork />} />
                <Route path="/messages" element={<Messages />} />

                <Route path="/organization/:slug" element={<Org />} />
                <Route path="/events/community" element={<CommunityEvents />} />
                <Route path="/events/followed" element={<FollowedEvents />} />

                <Route path="/chat" element={<ChatPanel />} />
                <Route path="/settings" element={<SettingsLayout />}>
                  <Route path="my-profile" element={<MyProfile />} />
                  <Route path="org-profile" element={<EditOrgProfile />} />
                  <Route path="events/:id?" element={<Events />} />
                </Route>
              </Route>
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/support" element={<Support />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-agreements"
                element={<TermsAndAgreements />}
              />
            </Route>
          </Routes>
        </Container>
      </PubNubProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
