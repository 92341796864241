import OrgHeader from "../../components/navigation/OrgHeader";
import AssetTable from "../../components/dashboard/AssetTable";

function BeaconAssets() {
  return (
    <>
      <OrgHeader />
      <AssetTable collapsible={false} />
    </>
  );
}

export default BeaconAssets;
