import { useNavigate, useParams } from "react-router";
import { BeaconStatus } from "../../constants";
import BeaconForm from "../../forms/BeaconForm";
import Loading from "../../components/Loading";
import { useFetchBeacon } from "../../api/beacons";

function EditBeacon() {
  let { slug: id } = useParams();
  const navigate = useNavigate();
  const { data: beaconData, isInitialLoading } = useFetchBeacon(id);
  if (beaconData?.status !== BeaconStatus.BEACON_STATUS_DRAFT) {
    navigate(`/beacons/${beaconData?.slug}`);
  }

  return (
    <>
      {isInitialLoading ? (
        <Loading />
      ) : (
        <BeaconForm
          beacon={beaconData}
          updateMode={
            beaconData && beaconData.status === BeaconStatus.BEACON_STATUS_DRAFT
          }
        />
      )}
    </>
  );
}

export default EditBeacon;
