import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useFetchCollaborationsRequests = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["collaborations", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(`/v1/collaborations/requests?page=${pageIndex + 1}`)
        .then((res) => res.data),
    select: (data) => {
      return data?.result;
    },
    useErrorBoundary: true,
    keepPreviousData: true,
  });
};

const useSendCollaborationRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (beaconId) => {
      return axiosPrivate.post(
        `/v1/collaborations/beacons/${beaconId}/send-request`
      );
    },
  });
};

const useApproveCollaborationRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (slug) => {
      return axiosPrivate.post(`/v1/collaborations/${slug}/approve-request`);
    },
  });
};

const useRejectCollaborationRequest = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (slug) => {
      return axiosPrivate.post(`/v1/collaborations/${slug}/reject-request`);
    },
  });
};

const useCancelCollaboration = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (slug) => {
      return axiosPrivate.post(
        `/v1/collaborations/${slug}/cancel-collaboration`
      );
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ["beacons", data?.result?.slug],
      });
    },
  });
};

export {
  useSendCollaborationRequest,
  useFetchCollaborationsRequests,
  useRejectCollaborationRequest,
  useApproveCollaborationRequest,
  useCancelCollaboration,
};
