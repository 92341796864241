import "./BeaconActivityGrid.scss";

import React, { useMemo, useState } from "react";
import DataGrid from "../DataGrid";
import assetThumbnail from "../../assets/images/asset_thumbnail.gif";
import { Image } from "react-bootstrap";

function AssetTable({ collapsible }) {
  const [data] = useState([
    {
      beaconId: "#######",
      notes: "Notes here",
      weight: "123 lbs",
      pieces: "11",
      items: "Boxes & Tape",
      assetType: "Category",
      assetImages: [assetThumbnail, assetThumbnail, assetThumbnail],
    },
    {
      beaconId: "#######",
      notes: "Notes here",
      weight: "123 lbs",
      pieces: "11",
      items: "Boxes & Tape",
      assetType: "Category",
      assetImages: [assetThumbnail, assetThumbnail, assetThumbnail],
    },
    {
      beaconId: "#######",
      notes: "Notes here",
      weight: "123 lbs",
      pieces: "11",
      items: "Boxes & Tape",
      assetType: "Category",
      assetImages: [assetThumbnail, assetThumbnail, assetThumbnail],
    },
  ]);
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const columns = useMemo(
    () => [
      {
        header: "Asset Images",
        accessorKey: "assetImages",
        cell: ({ cell }) => (
          <div className="d-flex gap-2">
            <Image src={cell.row.original.assetImages[0]} />
            <Image src={cell.row.original.assetImages[1]} />
            <Image src={cell.row.original.assetImages[2]} />
          </div>
        ),
      },
      { header: "Asset Type", accessorKey: "assetType" },
      { header: "Items", accessorKey: "items" },
      { header: "Pieces", accessorKey: "pieces" },
      { header: "Weight", accessorKey: "weight" },
      { header: "Notes", accessorKey: "notes" },
      { header: "Community Request ID", accessorKey: "beaconId" },
    ],
    []
  );

  return (
    <div>
      <DataGrid
        title="Asset Table"
        columns={columns}
        data={data}
        collapsible={collapsible}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
      />
    </div>
  );
}

export default AssetTable;
