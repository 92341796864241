import BeaconsList from "../../components/beacon/BeaconsList";
import OrgHeader from "../../components/navigation/OrgHeader";
import { BeaconStatus } from "../../constants";

function Beacons({ status }) {
  let label = "";
  if (status === BeaconStatus.BEACON_STATUS_IN_REVIEW)
    label = "Community Requests In Review";
  else if (status === BeaconStatus.BEACON_STATUS_PUBLISHED)
    label = "Community Requests In Progress";
  else if (status === BeaconStatus.BEACON_STATUS_COMPLETED)
    label = "Completed Community Requests";
  return (
    <>
      <OrgHeader />
      <BeaconsList title={`${label}`} status={status} />
    </>
  );
}

export default Beacons;
