import Button from "../Button";
import ContentModal from "./ContentModal";

function CancelBeaconOrCollaboration({
  show,
  setShow,
  onConfirm,
  onSecondaryAction,
  loading,
}) {
  return (
    <ContentModal show={show} setShow={setShow} className="content-modal">
      <div className="header">
        <h4 className="d-flex align-items-center gap-3">
          <span>Cancel Collaboration or Community Request?</span>
        </h4>
      </div>
      <div className="content">
        <p>
          You can cancel the collaboration or the Community Request. If you
          cancel the Collaboration the request will still be live in Community
          Requests.
        </p>
        <div className="actions d-flex align-content-center justify-content-end gap-3">
          <button className="cancel" onClick={() => onSecondaryAction()}>
            Cancel Community Request
          </button>
          <Button variant="danger" onClick={onConfirm} loading={loading}>
            Cancel Collaboration
          </Button>
        </div>
      </div>
    </ContentModal>
  );
}

export default CancelBeaconOrCollaboration;
